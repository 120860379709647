<mat-horizontal-stepper linear #stepper>
    <mat-step>
        <div class="step-content-wrapper" fxLayout="column"
             fxLayoutAlign="center center" style="padding-top: calc(100vh / 3)">
            <div [ngClass]="{'welcome-animation': stepper.selectedIndex == 0}" class="step-headline" fxLayout="row" fxLayoutAlign="center center">
                <div class="m-right20">Willkommen bei</div>
                <img class="full-logo" src="../../assets/images/talents-logo-dark.png" alt="talents-logo">
            </div>
            <div class="step-content">
                <div [ngClass]="{'welcome-animation2': stepper.selectedIndex == 0}" class="normal-text m-top20">
                    Wir freuen uns sehr, dass du dabei bist!<br>
                </div>
            </div>
        </div>
    </mat-step>

    <mat-step>
        <div class="step-content-wrapper" fxLayout="column"
             fxLayoutAlign="start start">
            <div class="step-headline">Das erwartet dich bei Talents</div>

            <div class="step-content">
                <div>
                    <div [ngClass]="{'point_animation1': stepper.selectedIndex == 1}" class="point">Du kannst dich nun als Coach auf der Jobeinstiegsplattform <a href="http://www.talentagent.de" target="_blank">talentagent.de<i class="mdi mdi-open-in-new"></i></a> von Uniwunder platzieren</div>
                    <div [ngClass]="{'point_animation1': stepper.selectedIndex == 1}" class="point">Deine Kontakte, die schon bei <a href="http://www.talentagent.de" target="_blank">talentagent.de<i class="mdi mdi-open-in-new"></i></a> sind, werden dir automatisch zugewiesen</div>
                    <div [ngClass]="{'point_animation1': stepper.selectedIndex == 1}" class="point">Du erhältst einen Einladungs-Link. Diesen kannst Du nutzen um Deinen sonstigen Kunden, Interessenten und Kontakten den Zugriff auf tausende von Stellenangeboten zu bieten und eine eindeutige Zuordnung und Betreuung durch Dich – weder Du noch der Kontakt zahlt dann dafür.</div>
                    <div [ngClass]="{'point_animation1': stepper.selectedIndex == 1}" class="point">Unterstütze deine Kontakte (Talente) im Bewerbungsprozess bei allgemeinen Fragen oder mit konkreten Stellenvorschlägen um einen Nachhaltigen und positiven Eindruck bei ihnen zu hinterlassen</div>
                </div>
            </div>
        </div>
    </mat-step>

    <mat-step>
        <div class="step-content-wrapper" fxLayout="column"
             fxLayoutAlign="start start">
            <div class="step-headline">So forderst du Talente an</div>
            <div class="step-content">
                <div class="point" [ngClass]="{'point_animation1': stepper.selectedIndex == 3}">Bewirb dich über ADAPT als Offizieller Talentagent-Coach und erhalte die Möglichkeit, Absolventen mit Beratungswunsch über die Plattform kennenzulernen</div>
                <div class="point" [ngClass]="{'point_animation1': stepper.selectedIndex == 3}">Buche Talente im ADAPT-Bereich "Online-Akquise". Tipp: Aktiviere ein wöchentliches Abo für bessere Organisation!</div>
                <div class="point" [ngClass]="{'point_animation2': stepper.selectedIndex == 3}">TALENTS ordnet dir dann nach und nach Talente zu, sobald diese verfügbar sind</div>
                <div class="point" [ngClass]="{'point_animation3': stepper.selectedIndex == 3}">Alle erhaltenen Talente sind automatisch in ADAPT im Kontaktebereich und ggf. in der Abrechnung verfügbar</div>
            </div>

        </div>
    </mat-step>

    <mat-step>
        <div class="step-content-wrapper" fxLayout="column"
             fxLayoutAlign="start start">
            <div class="step-headline">Damit wir uns wiedersehen können: Lege ein Passwort fest!</div>
            <div class="step-content">
                <div class="password-wrapper" fxLayout="column" fxLayoutAlign="start center">
                    <form [formGroup]="passwordForm">
                        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start start">

                            <mat-form-field>
                                <input matInput
                                       type="password"
                                       placeholder="Neues Passwort" required
                                       formControlName="newPassword">
                            </mat-form-field>

                            <mat-form-field>
                                <input matInput
                                       type="password"
                                       placeholder="Neues Passwort Wiederholen" required
                                       formControlName="newPasswordRepeat">
                            </mat-form-field>
                        </div>

                        <div style="min-height: 20px" class="m-bottom10 font-size-14">
                            <div class="warn" *ngIf="!passwordsEqual() && passwordForm.controls.newPasswordRepeat.dirty">
                                Die Passwörter stimmen nicht überein!
                            </div>
                        </div>
                    </form>

                    <div fxLayout="column" fxLayoutAlign="start start" class="password-hint">
                        <div fxLayout="row" fxLayoutAlign="start start" class="m-bottom10" [ngClass]="{'green': isPasswordLongEnough(),
                   'warn': !isPasswordLongEnough()}">
                            <i *ngIf="!isPasswordLongEnough()" class="mdi mdi-close-circle m-right10"></i>
                            <i *ngIf="isPasswordLongEnough()" class="mdi mdi-check-circle m-right10"></i>
                            <div class="font-size-14">Das Passwort muss mindestens 8 Zeichen lang sein</div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start" [ngClass]="{'green': passwordRequirementsAreTrue(),
                   'warn': !passwordRequirementsAreTrue()}">
                            <i *ngIf="!passwordRequirementsAreTrue()" class="mdi mdi-close-circle m-right10"></i>
                            <i *ngIf="passwordRequirementsAreTrue()" class="mdi mdi-check-circle m-right10"></i>
                            <div class="font-size-14">Das Passwort muss einen Kleinbuchstaben sowie eine Zahl oder einen Großbuchstaben enthalten
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-step>

    <mat-step>
        <div class="step-content-wrapper" fxLayout="column"
             fxLayoutAlign="start start">
            <div class="step-headline">Datenschutzbestimmungen</div>
            <div class="normal-text m-bottom40">Bitte akzeptiere die folgenden Datenschutzbestimmungen. Gehe stets sorgsam mit dir anvertrauten Daten um!</div>
            <div class="step-content">
                <div class="privacy-agreement">
                    <privacy-agreement-content></privacy-agreement-content>
                </div>
            </div>
        </div>
    </mat-step>

    <mat-step>
        <div class="step-content-wrapper" fxLayout="column"
             fxLayoutAlign="start start"  style="padding-top: calc(100vh / 3)">
            <div class="step-headline">Fertig!</div>
            <div class="step-content">
                <div class="normal-text">Zum Schluss solltest du nochmal einen Blick auf dein Profil werfen und ein Bild von dir hochladen</div>
                <div class="normal-text m-top20">Viel Spaß mit TALENTS und deinen Talenten!</div>
            </div>
        </div>
    </mat-step>



</mat-horizontal-stepper>
<div class="actions-box" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
    <button mat-flat-button class="hid-button" *ngIf="stepper.selectedIndex != 0" (click)="stepper.previous()">ZURÜCK</button>
    <button mat-flat-button class="hid-button primary" [disabled]="isNextDisabled()" (click)="performNextButtonAction()">
        {{getNextButtonLabel()}}
    </button>
</div>
